<template>
  <div></div>
</template>

<script>
import { SUBMIT_ORDER } from "../../graphql/mutations";
import {GET_ORDERS} from "../../graphql/queries"

export default {
  created() {
    if (this.$route.query.merchantReference) {
      console.log(this.$route.query);
      // let RequestId = this.$route.params.requestID;
      let OfferId = this.$route.params.offerID;
      let Cod = false;
      let pickUp = JSON.parse(this.$route.params.pickUp);
      let AddressId = this.$route.params.addressID;
      let NoonId = this.$route.query.orderId;
      this.order.OfferId = OfferId;
      this.order.Cod = Cod;
      this.order.pickUp = pickUp;
      this.order.AddressId = AddressId;
      this.order.RequestId = this.$route.query.merchantReference;
      // this.order.otp=1111;
      this.order.NoonId = NoonId;
      console.log(this.order);
      this.submitOrder(this.order);
    }
  },
  data() {
    return {
      order: {},
    };
  },
  methods: {
    async submitOrder(order) {
      this.$store.commit("glopal/isLoading", true);
      await this.$apollo
        .mutate({
          // Query
          mutation: SUBMIT_ORDER,
          // Parameters
          variables: order,
          update: (store, { data: { submitOrder } }) => {
            console.log(store);
            console.log(submitOrder);
            // Read the data from our cache for this query.
            const data = store.readQuery({ query: GET_ORDERS });
            console.log(data);
            data.getOrders.push(submitOrder);
            console.log(data.getOrders);
            store.writeQuery({
              query: GET_ORDERS,
              data,
            });
          },
        })
        .then((data) => {
          // Result
          console.log(data);
          this.$store.commit("glopal/isLoading", false);
          this.$router.replace("/orders");
          // this.postUrl = data.data.submitOrder.postUrl;
          // console.log(this.isCod);
          // if (!this.isCod) {
          // window.location.href = this.postUrl;
          // }
        })
        .catch((error) => {
          // Error
          this.$store.commit("glopal/isLoading", false);
          console.error(error);
          // We restore the initial user input
        });
    },
  },
};
</script>
