<template>
  <div>
    <div class="text-center" v-if="stepN == 1" style="direction: ltr">
      <h3 class="mb-4">دخل رمز التحقق</h3>
      <div style="display: flex; flex-direction: row; justify-content: center;">
        <v-otp-input
          ref="otpInput"
          input-classes="otp-input"
          separator=" "
          :num-inputs="4"
          :should-auto-focus="true"
          :is-input-num="true"
          @on-complete="veiwOTP"
        />
      </div>
    </div>
    <div v-if="stepN == 0">
      <p>لإكمال طلبك</p>
      <button
        @click="nextFunction"
        type="button"
        class="mt-3  btn btn-success btn-lg font-weight-bold"
        style="direction:ltr!important; "
      >
        <i class="whatsapp icon"></i> طلب رمز التحقق
      </button>
    </div>
    <div v-if="stepN == 1">
  <button
    @click="nextFunction"
    type="button"
    class="mt-3  btn btn-success btn-lg font-weight-bold"
    style="direction:ltr!important; "
  >
    <i class="whatsapp icon"></i> إعادة طلب رمز التحقق
  </button>
</div>
  </div>
</template>

<script >
import OtpInput from "@bachdgvn/vue-otp-input";

export default {
  data() {
    return {
      stepN: 0,
      otp: null,
    };
  },
  components: {
  "v-otp-input": OtpInput,
},
  methods: {
    veiwOTP(e) {
      //   this.$store.commit('glopal/isLoading',true);
      //   console.log(typeof e);
       this.otp = e;
       this.$emit('submitOTP',this.otp);
      //   let user = "+966" + this.number;
      //   console.log(user);
      //   this.$store
      // .dispatch("authentication/login", {
      //   phone: user,
      //   otp: this.otp,
      // })
      // .then((user) => {
      //   if (user == "Seller") {
      // this.$router.replace("/seller/requests");
      // return;
      //   }
      //   if (this.$store.state.glopal.stepNo == 2) {
      // this.$store.state.glopal.ModalState = 2;
      //   } else {
      // console.log(this.$router.currentRoute);
      // if (this.$router.currentRoute.path != "/Requests") {
      //   this.$router.replace("/Requests");
      // }
      //   }
      //   this.$children[0].otp = [];
      //   this.$store.commit('glopal/isLoading',false);
      // })
      // .catch((e)=>{
      //   this.$refs.otpInput.clearInput();
      //   this.$store.commit('glopal/isLoading',false);
      //   console.log(e);
      //   return e;
      // });
    },
    nextFunction() {
      window.open(
        "https://api.whatsapp.com/send?phone=966562240373&text=%D8%B1%D9%85%D8%B2%20%D8%A7%D9%84%D8%AA%D8%AD%D9%82%D9%82%20%D9%84%D8%A7%D9%83%D9%85%D8%A7%D9%84%20%D8%A7%D9%84%D8%B7%D9%84%D8%A8",
        "_blank"
      );
      this.stepN=1;
    },
  },
};
</script>
