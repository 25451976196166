<template>
  <div>
    <h3 class="text-right my-4">طريقة الشحن:</h3>
    <!-- <div class="accordion mt-2 text-right" id="accordionExample">
      <div class="scrapiz-card border rounded shipping-scrapiz-card" v-if="acceptsCollect">
        <div
          class="scrapiz-card-header bg-transparent row align-items-center"
          id="headingOne"
        >
          <span class="col-5 font-weight-bold text-right">
            إستلام من المتجر
          </span>
          <span class="col-4 font-weight-bold text-right"> 0 ريال </span>
          <div class="col-3 text-left">
            <label
              class="containerz"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              <input type="radio" name="foo" @click="shippingMethod(true, 0)" />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <div
          id="collapseOne"
          class="collapse"
          aria-labelledby="headingOne"
          data-parent="#accordionExample"
        >
          <div class="scrapiz-card-body font checkout-scrapiz-card">
            سوف يتم ارسال موقع التشليح إليك بعد اتمام الطلب.
          </div>
        </div>
      </div>
      <div class="scrapiz-card mb-2 border rounded">
        <div
          class="scrapiz-card-header bg-transparent row align-items-center"
          id="headingTow"
        >
          <span class="col-5 font-weight-bold text-right">
            شحن إلى المنزل
          </span>
          <span class="col-4 font-weight-bold text-right">
            {{ shippingPrice }} ريال
          </span>
          <div class="col-3 text-left">
            <label
              class="containerz"
              data-toggle="collapse"
              data-target="#collapseTow"
              aria-expanded="false"
              aria-controls="collapseTow"
            >
              <input
                type="radio"
                name="foo"
                @click="shippingMethod(false, shippingPrice)"
              />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <div
          id="collapseTow"
          class="collapse"
          aria-labelledby="headingTow"
          data-parent="#accordionExample"
        >
          <div class="scrapiz-card-body font checkout-scrapiz-card">
            يرجى تعبئة العنوان في الخطوة القادمة
          </div>
        </div>
      </div>
    </div> -->
    <div id="shippingAccordion">
      <div class="card">
        <div class="card-header complete-order-card-header row" id="pickupHeading">
       
            <!-- <button class="btn btn-link" data-toggle="collapse" data-target="#pickupCollapse" aria-expanded="true" aria-controls="pickupCollapse">
              Collapsible Group Item #1
            </button> -->
            <div class="col-5 font-weight-bold text-right">
              إستلام من المتجر
            </div>
            <div class="col-4 font-weight-bold text-right"> 0 ريال </div>
            <div class="col-3 text-left">
              <label
                class="containerz"
                data-toggle="collapse"
                data-target="#pickupCollapse"
                aria-expanded="false"
                aria-controls="pickupCollapse"
              >
                <input type="radio" name="foo" @click="shippingMethod(true, 0)" />
                <span class="checkmark"></span>
              </label>
            </div>
      
        </div>
    
        <div id="pickupCollapse" class="collapse " aria-labelledby="pickupHeading" data-parent="#shippingAccordion">
          <div class="card-body complete-order-card-body">
            سوف يتم ارسال موقع التشليح إليك بعد اتمام الطلب.
                   </div>
        </div>
      </div>
      <div class="card mt-1">
        <div class="card-header complete-order-card-header row" id="deliveryHeading">
          <div class="col-5 font-weight-bold text-right">
            شحن إلى المنزل
          </div>
          <div class="col-4 font-weight-bold text-right">
            {{ shippingPrice }} ريال
          </div>
          <div class="col-3 text-left">
            <label
              class="containerz"
              data-toggle="collapse"
              data-target="#deliveryCollapse"
              aria-expanded="false"
              aria-controls="deliveryCollapse"
            >
              <input
                type="radio"
                name="foo"
                @click="shippingMethod(false, shippingPrice)"
              />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <div id="deliveryCollapse" class="collapse" aria-labelledby="deliveryHeading" data-parent="#shippingAccordion">
          <div class="card-body complete-order-card-body">
            يرجى تعبئة العنوان في الخطوة القادمة
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      shipping: null,
    };
  },

  props: ["acceptsCollect", "shippingPrice"],
  methods: {
    shippingMethod(collect, shippingPrice) {
      this.shipping = shippingPrice;
      this.$emit("shippingMethod", collect, shippingPrice);
    },
  },
};
</script>

<style >

</style>