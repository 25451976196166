<template>
  <div class="container-fluid row m-0 p-0" v-if="getOffer">
    <div class="col-md-7 col-12">
      <div v-if="currentStepN == 1">
        <shipping-method v-bind:acceptsCollect="getOffer.acceptsCollect" v-bind:shippingPrice="getOffer.shippingAmount"
          v-on:shippingMethod="shippingMethod">
        </shipping-method>
      </div>

      <div v-if="currentStepN == 2">
        <div class="mt-4 container" v-if="!isUserHaveInfo">
          <user-info ref="userInfo"> </user-info>
        </div>

        <app-address class="text-center container m-4" style="margin-right: 0px !important" ref="myAddress"></app-address>
      </div>
      <div v-if="currentStepN == 3">
        <h3 class="text-right my-4">الدفع:</h3>
        <h6 class="text-right mb-4">جميع المعاملات آمنة ومشفرة</h6>

        <!-- <div class="accordion mt-2 text-right" id="accordionExample">
          <div class="scrapiz-card border rounded">
            <div
              class=" scrapiz-card-header  row align-items-center"
              id="headingOne"
            >
              <span class="col-3 font-weight-bold text-right">
                الدفع اون لاين
              </span>
              <div class="col-6 d-flex justify-content-start">
                <img
                  class="float-left"
                  src="../../img/mada.jpg"
                  alt=""
                  style="width: 2rem; height: 2rem"
                />
                <img
                  class="float-left"
                  src="../../img/visa.svg"
                  alt=""
                  style="width: 2rem; height: 2rem"
                />
                <img
                  class="float-left"
                  src="../../img/mastercard.png"
                  alt=""
                  style="width: 2rem; height: 2rem"
                />
                <img
                  class="float-left"
                  src="../../img/applePay.svg"
                  alt=""
                  style="width: 3rem; height: 2rem"
                />
              </div>

              <div class="col-3 text-left">
                <label
                  class="containerz"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  <input
                    type="radio"
                    name="foo"
                    @click="paymentMethod(false, 0)"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>

          <div class="scrapiz-card mb-2 border rounded" v-if="getOffer.acceptsCod">
            <div
              class="scrapiz-card-header bg-transparent row align-items-center"
              id="headingTow"
            >
              <span class="col-5 font-weight-bold text-right">
                دفع عند الاستلام
              </span>

              <span class="col-4 font-weight-bold text-right">
                +{{ getOffer.codCharge }} ريال
              </span>
              <div class="col-3 text-left">
                <label
                  class="containerz"
                  data-toggle="collapse"
                  data-target="#CODpaymentCollapse"
                  aria-expanded="false"
                  aria-controls="CODpaymentCollapse"
                >
                  <input
                    type="radio"
                    name="foo"
                    @click="paymentMethod(true, getOffer.codCharge)"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div> -->
        <div id="paymentAccordion">
          <div class="card">
            <div class="card-header complete-order-card-header row" id="onlinePaymentHeading">

              <div class="col-3 font-weight-bold text-right">
                الدفع اون لاين
              </div>
              <div class="col-6 d-flex justify-content-start">
                <img class="float-left" src="../../img/mada.jpg" alt="" style="width: 2rem; height: 2rem" />
                <img class="float-left" src="../../img/visa.svg" alt="" style="width: 2rem; height: 2rem" />
                <img class="float-left" src="../../img/mastercard.png" alt="" style="width: 2rem; height: 2rem" />
                <img class="float-left" src="../../img/applePay.svg" alt="" style="width: 3rem; height: 2rem" />
              </div>

              <div class="col-3 text-left">
                <label class="containerz" data-toggle="collapse" data-target="#onlinePaymentCollapse"
                  aria-expanded="false" aria-controls="onlinePaymentCollapse">
                  <input type="radio" name="foo" @click="paymentMethod(false, 0)" />
                  <span class="checkmark"></span>
                </label>
              </div>


            </div>


          </div>
          <div class="card mt-1">
            <div class="card-header complete-order-card-header row" id="codPaymentHeading">
              <div class="col-5 font-weight-bold text-right">
                دفع عند الاستلام
              </div>

              <div class="col-4 font-weight-bold text-right">
                +{{ getOffer.codCharge }} ريال
              </div>
              <div class="col-3 text-left">
                <label class="containerz" data-toggle="collapse" data-target="#CODpaymentCollapse" aria-expanded="false"
                  aria-controls="CODpaymentCollapse">
                  <input type="radio" name="foo" @click="paymentMethod(true, getOffer.codCharge)" />
                  <span class="checkmark"></span>
                </label>
              </div>

            </div>

          </div>

        </div>
      </div>

      <div v-if="currentStepN == 4" class="vld-overlay d-flex align-items-center">
        <div class="vld-background" style="background: rgba(0, 0, 0, 0.3) !important"></div>
        <transition name="fade" appear>
          <transition name="slide" appear>
            <div class="MyModal text-center" v-if="showModal">
              <div class="text-right">
                <span @click="showModal = false">
                  <i class="close icon" style="font-size: 1.4em"></i>
                </span>
              </div>
              <div class="MyModal" v-if="showModal">
                <app-otp v-on:submitOTP="submmit"> </app-otp>
              </div>
            </div>
          </transition>
        </transition>
      </div>
      <div class="d-flex justify-content-center mt-4">
        <button class="btn btn-danger btn-lg m-4" @click="backwardStep" v-if="currentStepN > 1 && currentStepN < 4">
          رجوع
        </button>
        <button class="btn bwj-btn btn-lg m-4" @click="submmit" v-if="currentStepN < 4">
          التالي
        </button>
      </div>
    </div>

    <div class="col-md-5 col-12 m-0 p-0 scrapiz-card border-0  " id="invoice-scrapiz-card" v-if="currentStepN < 4">
      <div class="scrapiz-card-body">
        <h2 class="text-center my-5">الفاتورة</h2>
        <div class="my-4">
          <h5 class="text-center my-4">سعر القطعة :{{ partPrice }} ريال</h5>
          <h5 class="text-center mb-4">سعر الشحن: {{ shipping }} ريال</h5>
          <h5 class="text-center mb-4" v-if="isCod">
            رسوم الدفع عند الاستلام: {{ codAmount }} ريال
          </h5>
        </div>
        <hr />
        <h5 class="text-center mb-4">
          المجموع : {{ partPrice + shipping + codAmount }} ريال
        </h5>
      </div>
    </div>
  </div>
</template>
<script>
import address from "../../components/Address.vue";
import otp from "./orderOTP.vue";
import shippingMethod from "./shippingMethod.vue";
import userInfo from "../../components/userInfio.vue";
import {
  GET_USER_REQUSTS,
  GET_FULL_OFFER,
  GET_USER_INFO,
  INIT_PAYMENT,
  GET_ORDERS,
} from "../../graphql/queries";
import { SUBMIT_ORDER } from "../../graphql/mutations";

export default {
  data() {
    return {
      currentStepN: 1,
      previousStepN: null,
      AddressStepN: 0,
      shipping: null,
      codAmount: null,
      isCollect: false,
      isCod: false,
      isUserHaveInfo: false,
      getOffer: {},
      routeParam: this.$route.params,
      getUserInfo: null,
      showModal: false,
      otp: null,
    };
  },
  components: {
    appAddress: address,
    appOtp: otp,
    shippingMethod: shippingMethod,
    userInfo: userInfo,
  },
  apollo: {
    getOffer: {
      query: GET_FULL_OFFER,
      variables() {
        return {
          RequestID: this.routeParam.requestID,
          OfferID: this.routeParam.offerID,
        };
      },
      result(result) {
        this.$store.commit("glopal/isLoading", false);
      },
    },

    getUserInfo: {
      query: GET_USER_INFO,
      result(result) {
        if (result.data.getUserInfo.firstName) {
          this.isUserHaveInfo = true;
        }
        this.$store.commit("glopal/isLoading", false);
      },
    },
  },
  methods: {
    async initiateOrder() {
      this.$store.commit("glopal/isLoading", true);

      console.log(this.isCollect);
      let order = {};
      let RequestId = this.$route.params.requestID;
      let OfferId = this.$route.params.offerID;
      let Cod = this.isCod;
      let pickUp = this.isCollect;
      if (!this.isCollect) {
        console.log(this.$store.state.glopal.selectedAddress);
        let AddressId = this.$store.state.glopal.selectedAddress._id;
        order.AddressId = AddressId;
      }
      if (!localStorage.user.isAuth) {
        let otp = this.otp;
        order.otp = otp;
      }
      order.RequestId = RequestId;
      order.OfferId = OfferId;
      order.Cod = Cod;
      order.pickUp = pickUp;

      console.log(order);

      if (!Cod) {
        await this.initiatePayment(order);
      } else {
        await this.submmitOrder(order);
      }
    },
    backwardStep() {
      this.currentStepN = this.previousStepN;
      this.previousStepN = this.previousStepN - 1;
      return;

    },
    async submmit(payload) {
      this.previousStepN = this.currentStepN;
      if (this.currentStepN == 1) {

        if (this.shipping == null) {
          window.alert("يجب ان تختار طريقة توصيل");
          return;
        }
        if (this.isCollect) {

          this.currentStepN = 3;
          return;
        }
        this.currentStepN = 2;
      } else if (this.currentStepN == 2) {
        if (!this.isUserHaveInfo) {
          this.$refs.userInfo
            .saveUserInfo()
            .then((data) => {
              console.log(data);
              this.AddressStepN = 1;
            })
            .catch((error) => {
              console.log(error);
              return;
            });
        } else {
          this.AddressStepN = 1;
        }

        if (this.AddressStepN == 1) {
          this.$refs.myAddress.submitAddress();
          this.currentStepN = 3;
        }
      } else if (this.currentStepN == 3) {
        if (this.codAmount == null) {
          window.alert("يجب ان تختار طريقة الدفع");
          return;
        }
        let user = JSON.parse(localStorage.user);
        if (user.isAuth) {
          await this.initiateOrder();
          if (this.isCod) {
            this.$router.replace("/orders", () => {
              location.reload();
            });
          }
        } else {
          this.currentStepN = 4;
          this.showModal = true;
        }
      } else if (this.currentStepN == 4) {
        console.log(payload);
        this.otp = parseInt(payload);
        this.showModal = false;
        await this.initiateOrder();
        if (this.isCod) {
          this.$router.replace("/orders", () => {
            location.reload();
          });
        }
      }
    },
    async initiatePayment(order) {
      //const { result } = useQuery(
      await this.$apollo
        .query({
          // Query
          query: INIT_PAYMENT,
          // Parameters
          variables: order,
        })
        .then((data) => {
          // Result
          console.log(data);
          this.$store.commit("glopal/isLoading", false);
          this.postUrl = data.data.initPayment;

          window.location.href = this.postUrl;
        })
        .catch((error) => {
          // Error
          this.$store.commit("glopal/isLoading", false);
          console.error(error);
          // We restore the initial user input
        });
    },
    async submmitOrder(order) {
      await this.$apollo
        .mutate({
          // Query
          mutation: SUBMIT_ORDER,
          // Parameters
          variables: order,
          update: (store, { data: { submitOrder } }) => {
            console.log(store);
            console.log(submitOrder);
            // Read the data from our cache for this query.
            const data = store.readQuery({ query: GET_ORDERS });

            console.log(data);
            data.getOrders.push(submitOrder);
            console.log(data.getOrders);
            store.writeQuery({
              query: GET_ORDERS,
              data,
            });
          },
        })
        .then((data) => {
          // Result
          console.log(data);
          this.$store.commit("glopal/isLoading", false);
        })
        .catch((error) => {
          // Error
          this.$store.commit("glopal/isLoading", false);
          console.error(error);
          // We restore the initial user input
        });
    },
    shippingMethod(collect, shippingPrice) {
      this.isCollect = collect;
      this.shipping = shippingPrice;
    },
    paymentMethod(cod, amount) {
      this.isCod = cod;
      this.codAmount = amount;
    },
  },
  computed: {
    partPrice: function () {
      return this.getOffer.offerAmount;
    },
    shippingPrice: function () {
      return this.getOffer.shippingAmount;
    },
  },
};
</script>

<style >
#invoice-scrapiz-card {
  background-color: rgb(242, 242, 242) !important;
}

.complete-order-card-header {
  background: #fff;
  margin-left: 0px;
  margin-right: 0px;
}

.complete-order-card-body {
  background-color: rgb(242, 242, 242) !important;
  text-align: right;
}

/* Customize the label (the container) */
.containerz {
  display: block;
  position: relative;
  padding-left: 2rem;
  margin-bottom: 2rem;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.containerz input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 2rem;
  width: 2rem;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.containerz:hover input~.checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.containerz input:checked~.checkmark {
  background-color: #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.containerz input:checked~.checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.containerz .checkmark:after {
  top: 25%;
  left: 25%;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: white;
}

.pay-img {
  height: 2rem;
}

.responsive-iframe {
  position: absolute;
  top: 25px;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 50%;
}

input[type="radio"] {
  margin-right: -0.5rem;
}

.checkout-scrapiz-card {
  background-color: rgb(242, 242, 242) !important;
}
</style>
